import * as React from 'react'
import { TMode } from './App'
import './Toggle.css'

export interface ToggleProps {
  mode: TMode,
  onClick: () => void
}

export default function Toogle({
  mode,
  ...props
}: ToggleProps) {
  return (
    <button
      className={`Toggle Toggle--${mode === 'edit' ? 'close' : 'open'}`}
      aria-label={mode === 'edit' ? 'Close Editor' : ''}
      {...props}>
       {mode === 'edit' ? '✓' : 'Edit'}
    </button>
  )
}
