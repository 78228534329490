import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-markdown'
// https://ace.c9.io/build/kitchen-sink.html
// import 'ace-builds/src-noconflict/theme-github'
// import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/theme-twilight'
import './Editor.css'

export interface EditorProps {
  hidden: boolean
  value: string
  onChange: (newValue: string) => void
  onScroll: (pos: number) => void
}

export default function Editor({
  hidden = false,
  value = '',
  onChange,
  onScroll
}: EditorProps) {

  const editorEl = useRef<HTMLElement>(null)
  const [width, setWidth] = useState('0px')
  const [height, setHeight] = useState('0px')
  
  useEffect(() => {
    const styles = getComputedStyle(document.documentElement)
    window.addEventListener('orientationchange', () => {
      setHeight(styles.getPropertyValue('--editor-height'))
      setWidth(styles.getPropertyValue('--editor-width'))
    })
    setHeight(styles.getPropertyValue('--editor-height'))
    setWidth(styles.getPropertyValue('--editor-width'))
  }, [height, width])
  
  if (hidden && !editorEl.current) {
    return null
  }
  return (
    <aside
      className={`Editor ${!hidden && 'Editor--open'}`}
      ref={editorEl}>
      <AceEditor
        name="Editor"
        placeholder="Start typing…"
        mode="markdown"
        //theme="chrome"
        theme="twilight"
        value={value}
        fontSize={16}
        width={width}
        height={height}
        showGutter={false}
        // showPrintMargin={false}
        // highlightActiveLine={true}
        focus={false}
        wrapEnabled={true}
        enableBasicAutocompletion={false}
        enableLiveAutocompletion={false}
        enableSnippets={false}
        tabSize={2}
        onLoad={(editor) => {
          // @ts-ignore
          editor.renderer.setScrollMargin(30);
        }}
        editorProps={{
          $blockScrolling: true
        }}
        setOptions={{
          enableMultiselect: false,
          // showInvisibles: true,
          autoScrollEditorIntoView: true, // doesnt
          // useWorker: true,
          // https://github.com/ajaxorg/ace/wiki/Configuring-Ace
          animatedScroll: false,
          // showLineNumbers: true,
          scrollPastEnd: true,
        }}
        // style={{
        //   border: '2rem solid #141414'
        // }}
        onChange={onChange}
        // onCopy={() => console.log('copy')}
        // onPaste={() => console.log('paste')}
        // onInput={() => console.log('input')}
        // onLoad={() => console.log('Editor loaded')}
        // onLoad={({ selection }) => selection.moveCursorTo(0, 0)}
        // cursorStart={1}
        onCursorChange={(selection, e) => {
          onScroll(selection.getCursor().row / (value.split('\n').length || 1))
        }}
        // annotations={[{ row: 0, column: 2, type: 'error', text: 'Some error.'}]}
      />
    </aside>
  )
}
