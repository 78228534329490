export const DEFAULT_CONTENT = `# H1 Start editing
## H2 To see some magic happen!

Text something like *Lorem Ipsum*
or ~~strikethrough~~ and \`backticks\`
but not realy. If you need help 
[use this descriptive link to www.google.com](https://www.google.com/)

---

### H3 Title

> Last But Not Least (a random blockquote)

Some words **Oh yeah!!** some words more words more words some b words some words more words more words
some words some words a more words more. Words some words some words more words more words
some words some words more words more words.

<a href="https://www.github.com">
  GitHub
</a>



- [x] yo
- [ ] sup

\`\`\`javascript
alert('hello')
\`\`\`

|A   |B   |C   |D   |
|----|----|----|----|
|1   |2   |3   |4   |
|11.1|22.2|33.3|44.4|

\`\`\`css
a { color: purple; }
\`\`\`


----

### Other stuff

~~~~~~
section with tilde ~
~~~~~~

this is one of the last lines

really

the very last
this is it!
`