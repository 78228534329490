import * as React from 'react'
import { useEffect, useState, useRef } from 'react'

import unified from 'unified'
// remark plugins
import parseMarkdown from 'remark-parse'
// @ts-ignore
import remark2html from 'remark-html'
// rehype plugins
// @ts-ignore
import parseHtml from 'rehype-parse'
// @ts-ignore
import rehype2html from 'rehype-stringify'
// HAST sanitizer
// @ts-ignore
import sanitize from 'rehype-sanitize'
// @ts-ignore
import gh from 'hast-util-sanitize/lib/github'

export interface RemarkProps {
  hidden: Boolean
  markdown: string
  scroll?: number
}

export default function Remark({
  hidden = false,
  markdown = '',
  scroll = 0
}: RemarkProps) {
  const [value, setValue] = useState<string>('')
  const mainEl = useRef<HTMLElement>(null)
  
  useEffect(() => {
    try {
      const intermediaryResult = unified()
        .use(parseMarkdown, {
          gfm: true,
          commonmark: false,
          pedantic: false
        })
        .use(remark2html)
        .processSync(markdown)
        .toString()
      const result = unified()
        .use(parseHtml)
        .use(rehype2html)
        .use(sanitize, gh)
        .processSync(intermediaryResult)
        .toString()
      setValue(result)
    } catch (err) {
      setValue(err)
      console.error(err)
    }
  }, [markdown])

  useEffect(() => {
    const { current: el } = mainEl
    if (!el) return
    const offset = (el.scrollHeight - el.offsetHeight + 60)
    el.scroll({
      top: scroll * offset,
      behavior: 'smooth'
    })
  }, [value, scroll])

  function handleInput(e: any) {
    console.log(e)
  }
  return (
    <main className="Result" ref={mainEl}>
      <article>
        <section
          dangerouslySetInnerHTML={{ __html: value }}
          onInput={handleInput}
        />
      </article>
    </main>
  )
}
