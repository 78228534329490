import * as React from 'react'
import { useEffect, useState } from 'react'
import Editor from './Editor'
import Result from './Result'
import Toggle from './Toggle'
import { DEFAULT_CONTENT } from './Default'
import './App.css'

export type TMode = 'view' | 'edit'

const decode = (data: string) => decodeURIComponent(escape(atob(data)))
const encode = (data: string) => btoa(unescape(encodeURIComponent(data)))

export default function App() {
  const [scroll, setScroll] = useState<number>(0)
  // eslint-disable-next-line no-restricted-globals
  const hash = decode(location.hash.slice(1))
  const [markdownContent, setMarkdownContent] = useState<string>(hash || DEFAULT_CONTENT)
  const [mode, setMode] = useState<TMode>('view')
  const toggleMode = () => setMode(mode === 'edit' ? 'view' : 'edit')

  function setContent(markdown: string) {
    // eslint-disable-next-line no-restricted-globals
    history.pushState({}, 'Editor', `#${encode(markdown)}`)
    setMarkdownContent(markdown)
  }

  useEffect(() => {
    const setNoScroll = mode === 'view' ? 'remove' : 'add'
    document.body.classList[setNoScroll]('no-scroll')
  }, [mode])

  useEffect(() => {
    window.addEventListener('hashchange', (e: HashChangeEvent) => {
      // eslint-disable-next-line no-restricted-globals
      setMarkdownContent(decode(location.hash.slice(1)))
    })
  }, [])

  return (
    <div className={`App App--${mode}`}>
      <Result
        hidden={mode === 'edit'}
        markdown={markdownContent}
        scroll={scroll} />
      <Editor
        value={markdownContent}
        hidden={mode === 'view'}
        onChange={setContent}
        onScroll={setScroll} />
      <Toggle
        mode={mode}
        onClick={toggleMode} />
    </div>
  )
}

